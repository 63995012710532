// pricingCardsData.ts

export type FeatureCardInfo = {
  titleCard?: string;
  subtitleCard?: string;

  expandCardsLabel?: string;
  closeCardsLabel?: string;

  pricing?: string;
  customClassPricing?: string;
  subTitlePricing?: string;
  ctaLabel?: string;
};

export type FeatureContent = {
  [key: string]: boolean;
};

export type FeatureListItem = {
  cardInfo?: FeatureCardInfo;

  header?: string;
  content: FeatureContent[];
};

export type PricingCardData = FeatureListItem[][];

export const featureLists: PricingCardData = [
  [
    {
      cardInfo: {
        titleCard: 'Fractal FF',
        subtitleCard: 'Power up your business',
        pricing: 'Free',
        ctaLabel: 'Get started',
      },

      content: [
        { '5 Fractals included': true },
        { '2 Livesystems included': true },
        { '1 Agent included': true },
        { '1 User included': true },
        { 'Private Livesystems': true },
      ],
    },
    {
      header: 'Fractal Cloud',
      content: [
        { 'SDK support': true },
        { 'Public Livesystems': false },
        { 'Private Fractals': false },
        { 'Organization support': false },
      ],
    },
    {
      header: 'Security',
      content: [
        { MFA: true },
        { 'Session tracking': true },
        { 'Public Personal Identity Providers': true },
        { 'Public Business Identity Providers': false },
        { RBAC: false },
        { 'On-Prem Identity providers': false },
      ],
    },
    {
      header: 'Collaboration',
      content: [{ History: false }, { 'Change Reviews': false }, { 'Issue tracking': false }],
    },
    {
      header: 'Advanced Features',
      content: [
        { 'Possibility of On-Prem dedicated setup': false },
        { 'Cloud Scan': false },
        { 'Cost Analysis': false },
        { 'Cost Predictions and Advice': false },
        { 'Cost Reduction': false },
      ],
    },
  ],
  [
    {
      cardInfo: {
        titleCard: 'Fractal Crowd',
        subtitleCard: 'Register as a single user',
        pricing: '$200/month',
        customClassPricing: 'text-blue-900',
        subTitlePricing: 'Billed per users',
        ctaLabel: 'Get started',
      },
      content: [
        { '10 Fractals included': true },
        { '5 Livesystems included': true },
        { '2 Agents included': true },
        { '1+ Users included': true },
        { 'Private Livesystems': true },
      ],
    },
    {
      header: 'Fractal Cloud',
      content: [
        { 'SDK support': true },
        { 'Public Livesystems': true },
        { 'Private Fractals': true },
        { 'Organization support': true },
      ],
    },
    {
      header: 'Security',
      content: [
        { MFA: true },
        { 'Session tracking': true },
        { 'Public Personal Identity Providers': true },
        { 'Public Business Identity Providers': true },
        { 'Basic RBAC': true },
        { 'On-Prem Identity providers': false },
      ],
    },
    {
      header: 'Collaboration',
      content: [
        { 'Limited History': true },
        { 'Change Reviews': false },
        { 'Issue tracking': false },
      ],
    },
    {
      header: 'Advanced Features',
      content: [
        { 'Possibility of On-Prem dedicated setup': false },
        { 'Cloud Scan': false },
        { 'Cost Analysis': false },
        { 'Cost Predictions and Advice': false },
        { 'Cost Reduction': false },
      ],
    },
  ],
  [
    {
      cardInfo: {
        titleCard: 'Fractal Enterparise',
        subtitleCard: 'Power up your business',
        pricing: 'Request a quote',
        ctaLabel: 'Get started',
      },
      content: [
        { 'Unlimited Fractals included': true },
        { 'Unlimited Livesystems included': true },
        { 'Unlimited agents included': true },
        { 'Unlimited users included': true },
        { 'Private Livesystems': true },
      ],
    },
    {
      header: 'Fractal Cloud',
      content: [
        { 'SDK support': true },
        { 'Public Livesystems': true },
        { 'Private Fractals': true },
        { 'Organization support': true },
      ],
    },
    {
      header: 'Security',
      content: [
        { MFA: true },
        { 'Session tracking': true },
        { 'Public Personal Identity Providers': true },
        { 'Public Business Identity Providers': true },
        { 'Advanced RBAC': true },
        { 'On-Prem Identity providers': true },
      ],
    },
    {
      header: 'Collaboration',
      content: [
        { 'Advanced History': true },
        { 'Change Reviews': true },
        { 'Issue tracking': true },
      ],
    },
    {
      header: 'Advanced Features',
      content: [
        { 'Possibility of On-Prem dedicated setup': true },
        { 'Cloud Scan': true },
        { 'Cost Analysis': true },
        { 'Cost Predictions and Advice': true },
        { 'Cost Reduction': true },
      ],
    },
  ],
];
